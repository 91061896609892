<template>
  <div class="filter-navbar">
    <template>
      <v-navigation-drawer right v-model="navBarObj.show" :width="navBarObj.width" :color="navBarObj.color"
      overlay-opacity="0.5" app temporary>
      <!-- overlay-opacity="0.5" app temporary permanent v-if="navBarObj.show"> -->
        <template #prepend>
          <v-card :class="[(navBarObj.header && navBarObj.header.class) ? navBarObj.header.class : '', 'd-flex align-center']"
          flat tile class="py-2">
            <span> {{ navBarObj.header && navBarObj.header.text || '' }} </span>
            <v-spacer></v-spacer>
            <v-btn icon small dark class="mr-n2" @click="navBarObj.show = false">
              <v-icon small> mdi-close-circle </v-icon>
            </v-btn>
            <!-- <v-menu transition="slide-x-reverse-transition" :close-on-content-click="false">
              <template #activator="{ on, attrs }">
                <v-btn x-small color="transparent" elevation="0" v-on="on" v-bind="attrs">
                  <v-icon left size="17" color="white"> mdi-plus-circle </v-icon>
                  <span class="caption white--text"> Add filter </span>
                </v-btn>
              </template>
              <v-card max-height="250" min-width="180" tile class="overflow-y-auto">
                <v-card-text class="pa-1">
                  <v-list>
                    <v-list-item v-for="(item, index) in navBarObj.fields" :key="index">
                      <v-list-item-title class="font-weight-medium"> {{ item.text }} </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-card-text>
              </v-card>
            </v-menu> -->
          </v-card>
        </template>
        <template>
          <template v-for="(field, fieldIndex) in navBarObj.fields">
            <v-layout row wrap align-center class="ma-3" :key="fieldIndex" v-if="field">
              <v-flex lg1>
                <v-checkbox dense hide-details v-model="field.filterselect"></v-checkbox>
              </v-flex>
              <v-flex lg11 class="pt-1 pl-2">
                <span class="subtitle-2 filter-item-text"> {{ field.text }} </span>
                <v-menu bottom offset-y v-if="field.filterselect">
                  <template #activator="{ on, attrs }">
                    <div class="d-inline-flex" v-on="on" v-bind="attrs">
                      <span class="caption px-1 font-weight-bold primary--text text--darken-4">
                        {{ (field.condition) ? (field.condition.text || getListOfFilters(field, true)) : getListOfFilters(field)[0].text }}
                      </span>
                      <v-icon x-small color="primary"> mdi-arrow-down-drop-circle </v-icon>
                    </div>
                  </template>
                  <v-list>
                    <v-list-item v-for="(item, index) in getListOfFilters(field)" :key="index"
                    @click="field.condition = item">
                      <v-list-item-content>
                        {{ item.text }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <!-- {{ field }} -->
              </v-flex>
              <v-flex lg1 v-if="field.filterselect"></v-flex>
              <v-flex lg11 class="pl-2" v-if="field.filterselect">
                <div class="mt-n2" v-if="(field.type === 'TEXT' || field.type === 'NUMBER' || field.type === 'TEXTAREA')">
                  <v-text-field dense hide-details :type="field.type === 'NUMBER' ? 'number' : 'text'"
                    :hide-spin-buttons="field.type === 'NUMBER'" v-model="field.filterModel" @keydown.enter="applyFilter">
                  </v-text-field>
                </div>
                <div class="mt-n1" v-else-if="(field.type === 'CHECKBOX' || field.type === 'RADIO' || field.type === 'SELECT')">
                  <v-select dense hide-details :items="field.items || checkboxFilterItems" v-model="field.filterModel"
                  :item-text="field.item_text || 'text'" :item-value="field.item_value || 'value'" :multiple="field.type === 'SELECT'">
                    <template #selection="{ item, index }" v-if="field.type === 'SELECT'">
                      <v-chip v-if="index === 0" small>
                        <span v-if="field.item_text && field.item_text.includes('.')"> {{ item[field.item_text.split('.')[0]][field.item_text.split('.')[1]] }} </span>
                        <span v-else> {{ item[field.item_text] || item.text || item }} </span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ field.filterModel.length - 1 }} others)
                      </span>
                    </template>
                  </v-select>
                </div>
                <div class="mt-n2" v-else-if="(field.type === 'DATE' || field.type === 'UNIX-DATE')">
                  <v-menu v-model="field.menu" :close-on-content-click="false">
                    <template #activator="{ on, attrs }">
                      <v-text-field v-on="on" v-bind="attrs" dense hide-details readonly v-model="field.filterModelDate"
                        @focus="field.filterModel = $_formatDate(field.filterModelDate, 'DD.MM.YYYY', 'YYYY-MM-DD')">
                      </v-text-field>
                    </template>
                    <v-date-picker no-title show-adjacent-months v-model="field.filterModel"
                      @input="field.filterModelDate = $_formatDate(field.filterModel, 'YYYY-MM-DD', 'DD.MM.YYYY'); field.menu = false;">
                    </v-date-picker>
                  </v-menu>
                </div>
                <div class="mt-n2" v-else-if="field.type === 'AUTOCOMPLETE'">
                  <v-autocomplete dense hide-details :items="field.items" v-model="field.filterModel"
                  :item-text="field.item_text || 'text'" :item-value="field.item_value || 'value'" multiple
                  ref="filterSearchText" :search-input.sync="field.searchInput" clearable deletable-chips
                  :loading="field.loading" :disabled="field.disabled && field.disabled()"
                  :return-object="field.returnObject" @keyup="$event.keyCode !== 13 ? loadSelectFieldValues({ field, fieldIndex }) : ''">
                    <template #selection="{ item, index }">
                      <v-chip v-if="index === 0" small>
                        <span v-if="field.item_text && field.item_text.includes('.')"> {{ item[field.item_text.split('.')[0]][field.item_text.split('.')[1]] }} </span>
                        <span v-else> {{ item[field.item_text] || item.text || item }} </span>
                      </v-chip>
                      <span v-if="index === 1" class="grey--text text-caption">
                        (+{{ field.filterModel.length - 1 }} others)
                      </span>
                    </template>
                  </v-autocomplete>
                </div>
              </v-flex>
            </v-layout>
          </template>
        </template>
        <template #append>
          <v-divider></v-divider>
          <v-card flat tile class="text-center py-2">
            <!-- <v-btn color="primary" x-small class="caption" @click="applyFilter(true)"> Save & Apply </v-btn> -->
            <v-btn color="success" x-small class="caption mx-2" @click="applyFilter"> Apply </v-btn>
            <v-btn color="error" x-small class="caption" outlined @click="resetFilter"> Reset </v-btn>
          </v-card>
        </template>
      </v-navigation-drawer>
    </template>
  </div>
</template>
<script>
export default {
  data () {
    return {
      navBarObj: {
        show: false
      },
      checkboxFilterItems: [{
        text: 'Yes',
        value: 1
      }, {
        text: 'No',
        value: 0
      }]
    }
  },
  mounted () {
    this.$root.$on('sideNavBar', ({ model, callback }) => {
      this.navBarObj = { ...model, show: true, callback }
    })
  },
  methods: {
    getListOfFilters ({ type, condition }, isGetFieldLabel) {
      let result = [{
        text: 'is',
        value: '$eq'
      }, {
        text: 'is not',
        value: '$ne'
      }]
      if (type === 'TEXT' || type === 'TEXTAREA') {
        result.push({ text: 'contains', value: '$regex' })
      } 
      else if (type === 'SELECT' || type === 'AUTOCOMPLETE') {
        result = [{
          text: 'is one of',
          value: '$in'
        }, {
          text: 'is not one of',
          value: '$nin'
        }]
      }
      if (isGetFieldLabel) return result.find(item => item.value === condition).text
      return result
    },
    loadSelectFieldValues () { // { field, fieldIndex } // live search implementation pending
      // console.log(field, fieldIndex, this.$refs.filterSearchText[0].lazySearch)
    },
    applyFilter () {
      const filterItems = this.navBarObj.fields.filter((filterItem) => filterItem.filterselect)
      let queryObj = {}
      for (const filter of filterItems) {
        if (filter.condition) filter.condition = typeof filter.condition === 'string' ? filter.condition : filter.condition.value
        else filter.condition = this.getListOfFilters(filter)[0].value
        switch(filter.type) {
          case 'DATE':
            // $eq, $ne
            switch (filter.condition) {
              case '$eq':
                {
                  let model = { start: `${filter.filterModel}T00:00`, end: `${filter.filterModel}T23:59` }
                  model = this.$_formatModelToUtcDate({ model: model, keys: ['start', 'end'], from: 'YYYY-MM-DDTHH:mm', to: 'YYYY-MM-DDTHH:mm' })
                  queryObj[`${filter.dbRef || filter.value}`] = { $gte: model.start, $lte: model.end }
                }
                break;
              case '$ne':
                queryObj[`${filter.dbRef || filter.value}`] = { [filter.condition]: filter.filterModel || '' }
                break;
            }
            break;
          case 'TEXT':
          case 'TEXTAREA':
            // $eq, $ne, $regex
            queryObj[`${filter.dbRef || filter.value}`] = filter.condition === '$regex' ? { [filter.condition]: filter.filterModel || '', $options: 'i' } : { [filter.condition]: filter.filterModel || '' }
            break;
          case 'SELECT':
          case 'AUTOCOMPLETE':
            // $in, $nin
            queryObj[`${filter.dbRef || filter.value}`] = { [filter.condition]: filter.filterModel || [] }
            break;
        }
      }
      this.navBarObj.callback({ filterItems, queryObj: { $match: { ...queryObj } } })
      this.navBarObj.show = false
      this.$root.$emit('snackbar', { message: 'Filter applied successfully', color: 'success' })
    },
    resetFilter () {
      for (const key in this.navBarObj.fields) {
        if (this.navBarObj.fields[key]) this.navBarObj.fields[key].filterselect = false
      }
      this.navBarObj.callback({ filterItems: [], queryObj: false })
      this.navBarObj.show = false
      this.$root.$emit('snackbar', { message: 'Filter reset successful', color: 'success' })
    }
  },
  beforeDestroy () {
    this.$root.$off('sideNavBar')
  }
}
</script>
<style scoped>
  .filter-navbar .filter-item-text {
    font-size: 0.80rem !important;
  }
</style>
